import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut
} from 'firebase/auth'
import { auth, actionCodeSettings } from '@/firebase/config'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import router from '@/router'

export const useAuthStore = defineStore('authStore', () => {
    const user = ref(null)
    const init = () => {
        console.log('init triggered !"£')
        onAuthStateChanged(auth, (userDetails) => {
            console.log('onAuthStateChanged triggered !"£')
            if (userDetails) {
                console.log('onAuthStateChanged triggered  ' + userDetails.email)
                const uid = userDetails.uid
                user.value = { email: userDetails.email, uid }
            } else {
                user.value = null
            }
        })
    }

    const loginUser = (credentials) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then((userCredential) => {
                    console.log('loginUser triggered')
                    // Signed in
                    const user = userCredential.user

                    resolve(user)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    const resetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email, actionCodeSettings)
            return true
        } catch (error) {
            console.error('Error in resetPassword:', error)
            throw error.message
        }
    }
    const getIdToken = () => {
        return new Promise((resolve, reject) => {
            auth.currentUser
                .getIdToken(true)
                .then((idToken) => {
                    resolve(idToken)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    const logoutUser = () => {
        signOut(auth)
            .then(() => {
                router.push('/login')
            })
            .catch((error) => {
                // An error happened.
            })
    }
    const getCurrentUser = async () => {
        const user = auth.currentUser

        if (user !== null) {
            let claims
            try {
                const idTokenResult = await user.getIdTokenResult()
                claims = idTokenResult.claims
            } catch (error) {}

            user.claims = claims
        }
        return user
    }

    return {
        loginUser,
        logoutUser,
        resetPassword,
        init,
        user,
        getIdToken,
        getCurrentUser
    }
})
